import * as React from "react";
import { v4 as uuid } from "uuid";
import { useParams, Navigate } from "react-router-dom";
import { Typography, Snackbar } from "@material-ui/core";

import { Page } from "./components/layout";
import { Loading } from "./components/misc";
import { StoryEditor } from "./components/stories";
import { useUserContext } from "./components/auth/UserContextProvider";
import { GET_BEACON_STORY, GET_BEACON_ITEMS } from "../api/graphQL/queries";
import { BeaconStory, GraphQlConnection, BeaconItem, BeaconStoryProjectStatus } from "../api/graphQL/queryModels";
import { useQuery, useMutation, gql } from "@apollo/client";
import { BeaconStoryInput } from "../api/graphQL/mutationModels";
import { UPSERT_BEACON_STORY } from "../api/graphQL/mutations";
import { useNotifications } from "./components/notifications/NotificationContextProvider";
import DocumentTitle from "./components/DocumentTitle";
import { BEACON_STORY_CORE_FIELDS_FRAGMENT } from "../api/graphQL/fragments";

const StoryEditorPage = () => {
    const { id } = useParams<{ id: string }>();
    const { user: currentUser, isContentAdmin } = useUserContext();
    const { setNotification } = useNotifications();

    const { data, loading, error } = useQuery<{ beaconStory: BeaconStory }>(GET_BEACON_STORY, {
        skip: !id,
        variables: {
            id
        }
    });

    const { data: itemsData, error: itemsError } = useQuery<{ beaconItems: GraphQlConnection<BeaconItem> }>(GET_BEACON_ITEMS, {
        variables: {
            first: 500
        }
    });

    const [savedId, setSavedId] = React.useState<string>();
    const [upsertBeaconStory, { error: beaconStoryUpsertError, loading: isSavingBeaconStory }] = useMutation<{
        beaconStoryUpsert: BeaconStoryInput;
    }>(UPSERT_BEACON_STORY, {
        onCompleted: (data) => {
            setSavedId(data.beaconStoryUpsert.id);
            setNotification("Story saved", "success");
        },
        update: (cache, mutationResponse) => {
            cache.modify({
                fields: {
                    beaconStories(existingItems) {
                        const newStoryRef = cache.writeFragment({
                            data: mutationResponse.data?.beaconStoryUpsert,
                            fragment: gql`
                                ${BEACON_STORY_CORE_FIELDS_FRAGMENT}
                            `,
                            fragmentName: "BeaconStoryCoreFields"
                        });

                        return id
                            ? existingItems
                            : {
                                  ...existingItems,
                                  edges: [
                                      {
                                          node: newStoryRef
                                      },
                                      ...existingItems.edges
                                  ]
                              };
                    }
                }
            });
        }
    });

    React.useEffect(() => {
        if (error?.message || beaconStoryUpsertError?.message || itemsError?.message) {
            setNotification(error?.message || beaconStoryUpsertError?.message || itemsError?.message, "error");
        }
    }, [error, beaconStoryUpsertError, itemsError, setNotification]);

    const story = !id
        ? ({
              id: uuid(),
              title: "",
              author: {
                  id: currentUser?.id
              } as unknown,
              headerImageUrl: null,
              dateCreated: new Date(),
              datePublished: null,
              description: "",
              images: [],
              isPublished: false,
              outcome: {
                  accomplishmentSentiment: 5,
                  clientSentiment: 5,
                  difficultySentiment: 5,
                  failureNotes: "",
                  improvementNotes: "",
                  stressLevelSentiment: 5,
                  successNotes: "",
                  teamCohesivenessSentiment: 5
              },
              previewDescription: "",
              projectStatus: BeaconStoryProjectStatus.NotStarted,
              selectedItems: [],
              projectId: null
          } as BeaconStory)
        : data?.beaconStory;

    const isFetching = !story || loading;

    const beaconItems = itemsData?.beaconItems.edges.map((e) => e.node) || [];
    const clients = story?.project?.clients || [];
    const firstClientId = clients.length ? clients[0].id : null;

    const storyInput = story
        ? ({
              id: story.id,
              title: story.title,
              author: {
                  id: story.author?.id || currentUser?.id
              },
              dateCreated: story.dateCreated,
              datePublished: story.datePublished,
              isPublished: story.isPublished,
              projectId: story.project?.id,
              projectStatus: story.projectStatus,
              description: story.description,
              images: story.images.map((i) => ({
                  title: i.title,
                  description: i.description,
                  filename: i.filename,
                  url: i.url,
                  optimizedFilename: i.optimizedFilename,
                  optimizedUrl: i.optimizedUrl,
                  thumbnailFilename: i.thumbnailFilename,
                  thumbnailUrl: i.thumbnailUrl,
                  showInGallery: i.showInGallery,
                  showInHeader: i.showInHeader
              })),
              outcome: {
                  successNotes: story.outcome.successNotes,
                  failureNotes: story.outcome.failureNotes,
                  improvementNotes: story.outcome.improvementNotes,
                  difficultySentiment: story.outcome.difficultySentiment,
                  clientSentiment: story.outcome.clientSentiment,
                  accomplishmentSentiment: story.outcome.accomplishmentSentiment,
                  teamCohesivenessSentiment: story.outcome.teamCohesivenessSentiment,
                  stressLevelSentiment: story.outcome.stressLevelSentiment
              },
              previewDescription: story.previewDescription,
              selectedItems: story.selectedItems.map((i) => ({
                  title: i.title,
                  explanation: i.explanation,
                  selectedItemId: i.selectedItem.id,
                  outcome: {
                      justification: i.outcome.justification,
                      sentiment: i.outcome.sentiment
                  },
                  consideredAlternativeItemIds: i.consideredAlternativeItems.map((i) => i.id)
              }))
          } as BeaconStoryInput)
        : null;

    const canEdit =
        !!storyInput &&
        !!currentUser?.id &&
        (currentUser.id === storyInput.author?.id ||
            isContentAdmin ||
            (!!story &&
                (story.project?.participants || []).some((participant) => !!participant && participant.person.id === currentUser.id)));

    const handleSave = async (story: BeaconStoryInput) => {
        await upsertBeaconStory({
            variables: {
                beaconStory: story
            }
        });
    };

    if (savedId) {
        return <Navigate to={`/stories/${savedId}`} />;
    }

    if (isFetching) {
        return (
            <div className="fetching-wrapper">
                <Loading text="Fetching..." />
            </div>
        );
    }

    return (
        <>
            <DocumentTitle pageTitle={`${id ? "Edit" : "New"} story`} />
            <Page noBackground noSidePadding>
                {!!storyInput && canEdit && (
                    <StoryEditor
                        beaconStory={storyInput}
                        beaconItemSummaries={beaconItems}
                        onSave={handleSave}
                        isSaving={isSavingBeaconStory}
                        clientId={firstClientId}
                    />
                )}
                {loading && <Typography>Initializing...</Typography>}
                {!loading && !!storyInput && !canEdit ? (
                    <div className="access-error">You do not have the authorization to access this page.</div>
                ) : null}
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    message={<span id="message-id">{error}</span>}
                    open={!!error}
                />
            </Page>
        </>
    );
};

export default StoryEditorPage;
