import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Box,
    Typography,
    Paper,
    Grid,
    MenuItem,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Button,
    FormHelperText
} from "@material-ui/core";
import { useFormik } from "formik";
import { nameof } from "ts-simple-nameof";
import { MarkdownEditor } from "../misc";
import { TagsField } from "../tags";
import { Form } from "../forms";
import { BeaconItemInput } from "../../../api/graphQL/mutationModels";
import {
    BeaconItemTag,
    BeaconItemEndorsement,
    BeaconItemCategory,
    BeaconItemDifficulty,
    BeaconItemResumeGroup
} from "../../../api/graphQL/queryModels";

interface InfoFormProps {
    beaconItem: BeaconItemInput;
    onSave: (item: BeaconItemInput) => Promise<unknown>;
    tags: BeaconItemTag[];
    resumeGroups: BeaconItemResumeGroup[];
}

const InfoForm = (props: InfoFormProps) => {
    const { beaconItem, tags, resumeGroups } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            ...beaconItem
        },
        onSubmit: async (item) => {
            setIsSaving(true);
            await props.onSave(item);
            setIsSaving(false);
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit} fullWidth>
            <Typography variant="h4" gutterBottom>
                Item
            </Typography>
            <Paper>
                <Box p={4}>
                    <Grid container direction="row" spacing={3}>
                        <Grid container item xs={12} md={6} spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="category-select">Category</InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        name={nameof<BeaconItemInput>((i) => i.category)}
                                        value={formik.values.category}
                                        onChange={formik.handleChange}
                                        inputProps={{
                                            name: "category",
                                            id: "category-select"
                                        }}
                                    >
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        <MenuItem value={BeaconItemCategory.None}>{BeaconItemCategory.None}</MenuItem>
                                        <MenuItem value={BeaconItemCategory.Methodology}>{BeaconItemCategory.Methodology}</MenuItem>
                                        <MenuItem value={BeaconItemCategory.Technique}>{BeaconItemCategory.Technique}</MenuItem>
                                        <MenuItem value={BeaconItemCategory.Tool}>{BeaconItemCategory.Tool}</MenuItem>
                                        <MenuItem value={BeaconItemCategory.Technology}>{BeaconItemCategory.Technology}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name={nameof<BeaconItemInput>((i) => i.name)}
                                        variant="outlined"
                                        label="Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name={nameof<BeaconItemInput>((i) => i.previewDescription)}
                                        variant="outlined"
                                        label="Preview description"
                                        value={formik.values.previewDescription}
                                        onChange={formik.handleChange}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <MarkdownEditor
                                    labelText="Description"
                                    value={beaconItem.description}
                                    onChange={(value: string) =>
                                        formik.setFieldValue(
                                            nameof<BeaconItemInput>((i) => i.description),
                                            value
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TagsField
                                    label="Tags"
                                    availableTags={tags}
                                    value={tags.filter((t) => formik.values.tagIds.indexOf(t.id) >= 0)}
                                    onChange={(value) => {
                                        formik.setFieldValue(
                                            nameof<BeaconItemInput>((i) => i.tagIds),
                                            value.map((t) => t.id)
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="resume-category-select">Resume Group</InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        name={nameof<BeaconItemInput>((i) => i.resumeGroupId)}
                                        value={formik.values.resumeGroupId}
                                        onChange={formik.handleChange}
                                        inputProps={{
                                            name: nameof<BeaconItemInput>((i) => i.resumeGroupId),
                                            id: "resume-category-select"
                                        }}
                                    >
                                        {resumeGroups.map((g) => (
                                            <MenuItem value={g.id} key="MenuItem 1">
                                                {g.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>Use for grouping related items on resumes</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="endorsement-select">Endorsement</InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        name={nameof<BeaconItemInput>((i) => i.endorsement)}
                                        value={formik.values.endorsement}
                                        onChange={formik.handleChange}
                                        inputProps={{
                                            name: nameof<BeaconItemInput>((i) => i.endorsement),
                                            id: "endorsement-select"
                                        }}
                                    >
                                        <MenuItem value={BeaconItemEndorsement.None}>{BeaconItemEndorsement.None}</MenuItem>
                                        {/* <MenuItem value={BeaconItemEndorsement.Adopt}>{BeaconItemEndorsement.Adopt}</MenuItem>
                                        <MenuItem value={BeaconItemEndorsement.Consider}>{BeaconItemEndorsement.Consider}</MenuItem> */}
                                        <MenuItem value={BeaconItemEndorsement.Avoid}>{BeaconItemEndorsement.Avoid}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {formik.values.category === BeaconItemCategory.Technique && (
                                <>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                label="People"
                                                placeholder="0"
                                                name={nameof<BeaconItemInput>((i) => i.people)}
                                                value={formik.values.people}
                                                onChange={formik.handleChange}
                                                helperText="How many people are needed?"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <TextField
                                                variant="outlined"
                                                label="Preparation"
                                                placeholder="0"
                                                name={nameof<BeaconItemInput>((i) => i.preparation)}
                                                value={formik.values.preparation}
                                                onChange={formik.handleChange}
                                                helperText="Hours of prep needed? (eg. 1 hr, 2 days)"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <TextField
                                                variant="outlined"
                                                label="Duration"
                                                placeholder="0"
                                                name={nameof<BeaconItemInput>((i) => i.duration)}
                                                value={formik.values.duration}
                                                onChange={formik.handleChange}
                                                helperText="Time to complete? (eg. 2 hrs, 1 day)"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined">
                                            <InputLabel htmlFor="difficulty-select">Difficulty</InputLabel>
                                            <Select
                                                variant="outlined"
                                                name={nameof<BeaconItemInput>((i) => i.difficulty)}
                                                value={formik.values.difficulty || ""}
                                                onChange={formik.handleChange}
                                                inputProps={{
                                                    name: "difficulty",
                                                    id: "difficulty-select"
                                                }}
                                            >
                                                <MenuItem value={BeaconItemDifficulty.None}>{BeaconItemDifficulty.None}</MenuItem>
                                                <MenuItem value={BeaconItemDifficulty.Easy}>{BeaconItemDifficulty.Easy}</MenuItem>
                                                <MenuItem value={BeaconItemDifficulty.Medium}>{BeaconItemDifficulty.Medium}</MenuItem>
                                                <MenuItem value={BeaconItemDifficulty.Hard}>{BeaconItemDifficulty.Hard}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" disabled={!formik.values.name || isSaving}>
                                {isSaving ? "Saving..." : "Save"}
                            </Button>
                            <Button component={RouterLink} to={formik.values.dateCreated ? `/items/${beaconItem.id}` : `/?view=items`}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Form>
    );
};

export default InfoForm;
