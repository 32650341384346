import * as React from "react";
import { useParams, Navigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { InfoForm } from "./components/items";
import { Page } from "./components/layout";
import { Loading } from "./components/misc";
import { useUserContext } from "./components/auth/UserContextProvider";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
    BeaconItem,
    GraphQlConnection,
    BeaconItemTag,
    BeaconItemCategory,
    BeaconItemDifficulty,
    BeaconItemEndorsement,
    BeaconItemResumeGroup
} from "../api/graphQL/queryModels";
import { BEACON_ITEM_FIELDS_FRAGMENT } from "../api/graphQL/fragments";
import { GET_BEACON_ITEM, GET_BEACON_TAGS, GET_BEACON_ITEM_RESUME_GROUPS } from "../api/graphQL/queries";
import { BeaconItemInput } from "../api/graphQL/mutationModels";
import { UPSERT_BEACON_ITEM } from "../api/graphQL/mutations";
import { useNotifications } from "./components/notifications/NotificationContextProvider";
import DocumentTitle from "./components/DocumentTitle";

const ItemEditorPage = () => {
    const { user: currentUser, isContentAdmin } = useUserContext();

    const { id } = useParams<{ id: string }>();
    const { setNotification } = useNotifications();

    const { data, loading, error } = useQuery<{ beaconItem: BeaconItem }>(GET_BEACON_ITEM, {
        skip: !id,
        variables: {
            id
        }
    });

    const {
        data: tagsData,
        loading: tagsLoading,
        error: tagsError
    } = useQuery<{ beaconTags: GraphQlConnection<BeaconItemTag> }>(GET_BEACON_TAGS);

    const {
        data: resumeGroupData,
        loading: resumeGroupsLoading,
        error: resumeGroupsError
    } = useQuery<{
        beaconItemResumeGroups: GraphQlConnection<BeaconItemResumeGroup>;
    }>(GET_BEACON_ITEM_RESUME_GROUPS);

    const [savedId, setSavedId] = React.useState<string>();
    const [upsertBeaconItem, { error: beaconItemUpsertError }] = useMutation<{ beaconItemUpsert: BeaconItemInput }>(UPSERT_BEACON_ITEM, {
        onCompleted: (data) => {
            setSavedId(data.beaconItemUpsert.id);
            setNotification("Item saved", "success");
        },
        update: (cache, mutationResponse) => {
            cache.modify({
                fields: {
                    beaconItems(existingItems) {
                        const newItemRef = cache.writeFragment({
                            data: mutationResponse.data?.beaconItemUpsert,
                            fragment: gql`
                                ${BEACON_ITEM_FIELDS_FRAGMENT}
                            `
                        });

                        return id
                            ? existingItems
                            : {
                                  ...existingItems,
                                  edges: [
                                      {
                                          node: newItemRef
                                      },
                                      ...existingItems.edges
                                  ]
                              };
                    }
                }
            });
        }
    });

    React.useEffect(() => {
        if (error?.message || beaconItemUpsertError?.message || tagsError?.message || resumeGroupsError?.message) {
            setNotification(error?.message || beaconItemUpsertError?.message || tagsError?.message || resumeGroupsError?.message, "error");
        }
    }, [error, beaconItemUpsertError, tagsError, resumeGroupsError, setNotification]);

    const item = !id
        ? ({
              id: uuid(),
              name: "",
              author: {
                  id: currentUser?.id
              } as unknown,
              category: BeaconItemCategory.None,
              difficulty: BeaconItemDifficulty.None,
              dateCreated: null,
              description: "",
              duration: "",
              endorsement: BeaconItemEndorsement.None,
              isDeleted: false,
              people: "",
              preparation: "",
              previewDescription: "",
              tagIds: [],
              resumeGroup: null
          } as BeaconItem)
        : data?.beaconItem;

    const tags = tagsData?.beaconTags.edges.map((e) => e.node);
    const resumeGroups = resumeGroupData?.beaconItemResumeGroups.edges.map((e) => e.node);

    const itemInput = item
        ? ({
              id: item.id,
              name: item.name,
              author: {
                  id: item.author?.id || currentUser?.id
              },
              category: item.category,
              difficulty: item.difficulty,
              dateCreated: item.dateCreated,
              description: item.description,
              duration: item.duration,
              endorsement: item.endorsement,
              isDeleted: item.isDeleted,
              people: item.people,
              preparation: item.preparation,
              previewDescription: item.previewDescription,
              tagIds: item.tags?.map((t) => t.id) || [],
              resumeGroupId: item.resumeGroup?.id
          } as BeaconItemInput)
        : null;

    const canEdit = (!!currentUser?.id && item?.author?.id === currentUser.id) || isContentAdmin;

    const saveBeaconItem = async (beaconItem: BeaconItemInput) => {
        await upsertBeaconItem({
            variables: {
                beaconItem
            }
        });
    };

    if (savedId) {
        return <Navigate to={`/items/${savedId}`} />;
    }

    return (
        <>
            <DocumentTitle pageTitle={`${id ? "Edit" : "New"} item`} />
            <Page noBackground>
                {loading ? (
                    <div className="fetching-wrapper">
                        <Loading text="Fetching..." />
                    </div>
                ) : null}
                {!!item && !canEdit ? <div className="access-error">You do not have the authorization to access this page.</div> : null}
                {!!itemInput && !loading && !tagsLoading && !resumeGroupsLoading && canEdit ? (
                    <InfoForm beaconItem={itemInput} onSave={saveBeaconItem} tags={tags || []} resumeGroups={resumeGroups || []} />
                ) : null}
            </Page>
        </>
    );
};

export default ItemEditorPage;
