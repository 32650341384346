import * as React from "react";
import { Dialog, DialogContent, TextField, Grid, InputAdornment, IconButton, Icon, DialogTitle, makeStyles } from "@material-ui/core";

import debounce from "lodash/debounce";
import SearchResultList from "./SearchResultList";
import { useQuery } from "@apollo/client";
import { SEARCH_BEACON_CONTENT } from "../../../api/graphQL/queries";
import { BeaconContentSearchResponse } from "../../../api/graphQL/queryModels";

const useStyles = makeStyles(() => ({
    root: {
        padding: 8
    },
    title: {
        padding: 0
    },
    searchBox: {
        padding: 8
    },
    paper: {}
}));

interface SearchDialogProps {
    open: boolean;
    searchText: string;
    onTextChange: (value: string) => unknown;
    onClose: () => unknown;
}

const SearchDialog = (props: SearchDialogProps) => {
    const classes = useStyles();

    const [workingSearchText, setWorkingSearchText] = React.useState("");

    const { data, loading: isSearching } = useQuery<{ beaconContentSearch: BeaconContentSearchResponse }>(SEARCH_BEACON_CONTENT, {
        skip: !props.searchText,
        variables: {
            query: props.searchText
        }
    });
    const response = data?.beaconContentSearch;

    const onItemSelected = () => {
        props.onClose();
    };

    const searchTextChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const searchText = event.target.value;
        setWorkingSearchText(searchText);
        debouncedExecuteSearch(searchText);
    };

    const executeSearch = (searchText: string) => {
        props.onTextChange(searchText);
    };

    const debouncedExecuteSearch = React.useRef(debounce((searchText: string) => executeSearch(searchText), 500)).current;

    return (
        <Dialog fullScreen open={props.open} onClose={props.onClose} className={classes.root} PaperProps={{ className: classes.paper }}>
            <DialogTitle className={classes.title}>
                <TextField
                    className={classes.searchBox}
                    color="primary"
                    autoFocus
                    fullWidth
                    placeholder="Search"
                    value={workingSearchText}
                    onChange={searchTextChanged}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            fontSize: 32
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={props.onClose}>
                                    <Icon style={{ fontSize: 32 }}>arrow_back</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={props.onClose}>
                                    <Icon style={{ fontSize: 32 }}>close</Icon>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {!isSearching && response && !!response.results.length && (
                            <SearchResultList results={response.results} onItemSelected={onItemSelected} />
                        )}
                    </Grid>
                    {isSearching ? (
                        <Grid item xs={12}>
                            <div className="searching-text">
                                <span>Searching...</span>
                            </div>
                        </Grid>
                    ) : null}
                    {!isSearching && !response ? (
                        <Grid item xs={12}>
                            <div className="starter-text">
                                <span>Looking for something? We can help!</span>
                            </div>
                        </Grid>
                    ) : null}
                    {!isSearching && response && !response.results.length && (
                        <Grid item xs={12}>
                            <div className="no-results-text">
                                <span>Sorry! No results found for &quot;{props.searchText}&quot;</span>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SearchDialog;
